import Analytics from "@/js/analytics/analytics";
import { getUploadedImageById } from "@/js/annotation";
import { postFetchMultipleAnnotations, postCreateOrUpdateAnnotation } from "../../server/annotation-server";
import { ANNOTATION_TYPE as HEAP_ANNOTATION_TYPE } from "@/constants/heapEvents.js";
import { ANNOTATION_TYPE } from "@/constants/annotationStatus";
import { toRound } from "@/js/common/formatNumber";
import { i18n } from "@/main";

export const namespaced = true;

export const state = {
  annotations: [],
};

export const mutations = {
  MUTATE_ANNOTATIONS(state, annotations = []) {
    state.annotations = annotations.map(({ id, stepId, elements }) => ({
      id,
      stepId,
      elements,
    }));
  },
  ADD_ANNOTATIONS(state, { annotation, stepId }) {
    const index = state.annotations.findIndex((annotation) => annotation.stepId === stepId);
    if (index !== -1) {
      state.annotations[index].elements.push(annotation);

      const type = HEAP_ANNOTATION_TYPE[annotation.type];
      Analytics.setTrack({
        category: "BuilderMain",
        action: "Edit Video",
        name: `Add Annotation ${type}`,
      });
    }
  },
  DELETE_ANNOTATION(state, { deletedIndex, annotationId, stepId }) {
    const targetIndex = state.annotations.findIndex((annotation) => annotation.stepId === stepId);
    const index = deletedIndex
      ? deletedIndex
      : state.annotations[targetIndex].elements.findIndex(({ id }) => id === annotationId);
    if (index !== -1 && targetIndex !== -1) {
      state.annotations[targetIndex].elements.splice(index, 1);
    }
  },
  DELETE_ANNOTATION_BY_STEP_ID(state, stepId) {
    if (stepId == null) {
      console.error(`This delete id (${stepId}) is invalid`);
      return;
    }
    state.annotations = state.annotations.filter((annotation) => annotation.stepId !== stepId);
  },
  MUTATE_ANNOTATION_PROPERTY(state, { stepId, key, value, id }) {
    const index = state.annotations.findIndex((annotation) => annotation.stepId === stepId);
    if (index !== -1) {
      const targetAnnotation = state.annotations[index];
      const elementIndex = targetAnnotation.elements.findIndex((element) => element.id === id);
      if (elementIndex !== -1) {
        targetAnnotation.elements[elementIndex][key] = value;
      }
    }
  },
};

export const getters = {
  getters_get_annotation_by_id: (state) => (stepId) => {
    return state.annotations.find((annotation) => annotation.stepId === stepId);
  },
};

export const actions = {
  storeMultipleAnnotation({ commit }, { annotations, stepIds }) {
    commit("MUTATE_ANNOTATIONS", formatAnnotation(annotations, stepIds));
  },
  async fetchMultipleAnnotation({ commit }, stepIds) {
    const { data, ok, error } = await postFetchMultipleAnnotations(stepIds);
    if (ok) {
      commit("MUTATE_ANNOTATIONS", formatAnnotation(data.items, stepIds));
    } else {
      console.error(error);
    }
  },
  async updateAndFetchAnnotation({ commit, state, dispatch }, workflowId) {
    const { ok: uploadedOk, uploadedImageById } = await getUploadedImageById(state.annotations);

    if (!uploadedOk) {
      dispatch("global/openAlert", { message: i18n.t("alert.unexpectedError"), type: "error" }, { root: true });
      return;
    }
    const annotations = state.annotations.map(({ elements, ...annotation }) => ({
      ...annotation,
      elements: elements.map(({ id, type, originalCanvasWidth, originalCanvasHeight, ...el }) => ({
        id,
        type,
        originalCanvasWidth,
        originalCanvasHeight,
        startTime: toRound(el.startTime),
        endTime: toRound(el.endTime),
        top: toRound(el.top),
        left: toRound(el.left),
        scaleX: toRound(el.scaleX || 1),
        scaleY: toRound(el.scaleY || 1),
        ...(el.width && { width: toRound(el.width) }),
        ...(el.height && { height: toRound(el.height) }),
        ...(type === ANNOTATION_TYPE.PEN && {
          path: el.path,
          fill: el.fill,
          strokeWidth: el.strokeWidth,
          stroke: el.stroke,
          strokeColor: el.strokeColor,
        }),
        ...(type === ANNOTATION_TYPE.ARROW && {
          strokeWidth: el.strokeWidth,
          stroke: el.stroke,
          strokeColor: el.strokeColor,
          startX: el.startX,
          startY: el.startY,
          endX: el.endX,
          endY: el.endY,
        }),
        ...(type === ANNOTATION_TYPE.TEXT && {
          fill: el.fill,
          backgroundColor: el.backgroundColor,
          text: el.text,
          fontWeight: el.fontWeight,
          fontStyle: el.fontStyle,
        }),
        ...(type === ANNOTATION_TYPE.IMAGE && {
          imageUrl: uploadedImageById[id] || el.imageUrl,
        }),
      })),
    }));
    const { data, ok } = await postCreateOrUpdateAnnotation({ annotations, workflowId });
    if (ok) {
      commit("MUTATE_ANNOTATIONS", data.items);
    }
  },
  async addNewAnnotationByStepIds({ commit, state }, stepIds) {
    const { data, ok, errorMessage } = await postFetchMultipleAnnotations(stepIds);
    if (ok) {
      stepIds.forEach((stepId) => {
        let annotation = {
          stepId,
          elements: [],
        };
        const index = data.items.findIndex((item) => item.stepId === stepId);
        if (index !== -1) {
          const { id, stepId, elements } = data.items[index];
          annotation = { id, stepId, elements };
        }
        state.annotations.push(annotation);
      });
    } else {
      console.error(errorMessage);
    }
  },
  deleteAnnotationByStepId({ commit }, stepId) {
    commit("DELETE_ANNOTATION_BY_STEP_ID", stepId);
  },
};

const formatAnnotation = (items, stepIds) => {
  stepIds.forEach((stepId) => {
    if (!items.find((item) => item.stepId === stepId)) {
      items.push({
        stepId,
        elements: [],
      });
    }
  });
  const indexById = new Map(stepIds.map((stepId, index) => [stepId, index]));
  items.sort((a, b) => indexById.get(a.stepId) - indexById.get(b.stepId));
  return items;
};
