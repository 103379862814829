<template>
  <div class="d-playlist-drag">
    <div class="dropzone-div">
      <div
        class="dropzone-div-inner"
        :class="{ 'show-dropzone': dragOverIdx == -1 && dragFromIdx != 0 }"
        @dragover="onDragover($event, -1)"
        @dragend="onDragEnd($event)"
        @drop="onDrop($event)"
      ></div>
    </div>
    <div v-for="(workflow, idx) in value" :key="idx" class="workflow-item">
      <div class="workflow-card">
        <div class="checked-circle-div center-horizontally">
          <button class="checked-circle-btn" @click="addOrRemoveSelectedWorkflows(workflow.id)">
            <div
              v-if="selectedWorkflowsIds.indexOf(workflow.id) === -1"
              class="unchecked-circle"
              :class="{ 'show-unchecked-circle': selectedWorkflowsIds.length > 0 }"
            ></div>
            <icon-base v-else width="20" height="20">
              <d-icon-circle-checked />
            </icon-base>
          </button>
          <p class="workflow-index" autotest="skillsbuilder-part">{{ getStepNum(idx) }}</p>
        </div>
        <div
          :draggable="selectedWorkflowsIds.length === 0"
          class="workflow-div"
          :class="{ 'workflow-div-darken': !workflow.published || workflow.deleted || workflow.noPermission }"
          @dragstart="onDragStart($event, idx)"
          @dragend="onDragEnd($event)"
          @drop="onDrop($event)"
        >
          <div class="workflow-poster center-horizontally">
            <div v-if="workflow.deleted || workflow.noPermission" class="poster-delete">
              <div class="fullscreen-center">
                <icon-base width="48" height="48" color="white" opacity="0.3">
                  <d-icon-no-access />
                </icon-base>
              </div>
            </div>
            <div v-else-if="!workflow.published" class="poster-darken">
              <div class="fullscreen-center">{{ $t("all.unpublished") }}</div>
            </div>
            <d-image :aspectRatio="16 / 9" :src="workflow.poster" class="video-poster"></d-image>
          </div>
          <div class="delete-step-div center-horizontally">
            <button
              v-if="selectedWorkflowsIds.length === 0"
              class="delete-video-btn"
              @click="deletedSingleWorkflow(workflow.id)"
            >
              <icon-base color="#0C0C0E" width="20" height="20">
                <d-icon-trash />
              </icon-base>
            </button>
          </div>
          <div class="title-div center-horizontally" @dragover="onDragover($event, idx - 1)">
            <div v-if="workflow.deleted || workflow.noPermission" class="title-box">
              <p v-if="workflow.deleted" class="title-text text-truncate">{{ $t("skills.workflowDeleted") }}</p>
              <p v-else class="title-text text-truncate">{{ $t("admin.noPermission") }}</p>
              <p class="time-step-text">
                0:00
                <span>
                  <span class="white-dot"></span>
                  {{ $tc("all.numStep", 0) }}
                </span>
                <span class="white-dot"></span>
                <button class="underline cursor-pointer" @click="openVersionHistory({ workflowId: workflow.id })">
                  {{ $t("versionHistory.viewPublishHistory") }}
                </button>
                <span v-if="workflow.privateWorkspace">
                  <font-awesome-icon class="ml-1" :icon="['fas', 'lock-keyhole']" />
                  <span class="private-label">{{ $t("all.private") }}</span>
                </span>
              </p>
            </div>
            <div v-else class="title-box">
              <p class="title-text text-truncate" autotest="skillsbuilder-workflow-title">
                {{ getWorkflowDisplayTitle(workflow) }}
              </p>
              <p class="time-step-text">
                <span autotest="skillsbuilder-workflow-length">
                  {{ videoDuration(workflow.videoDuration) }}
                </span>
                <span autotest="skillsbuilder-workflow-steps">
                  <span class="white-dot"></span>
                  {{ $tc("all.numStep", workflow.steps) }}
                  <span class="white-dot"></span>
                  <button class="underline cursor-pointer" @click="openVersionHistory({ workflowId: workflow.id })">
                    {{ $t("versionHistory.viewPublishHistory") }}
                  </button>
                </span>
                <span v-if="workflow.privateWorkspace">
                  <font-awesome-icon class="ml-1" :icon="['fas', 'lock-keyhole']" />
                  <span class="private-label">{{ $t("all.private") }}</span>
                </span>
              </p>
              <div class="quiz-indicator-wrapper">
                <d-quiz-indicator :workflowId="workflow.id" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dropzone-div">
        <div
          class="dropzone-div-inner"
          :class="{ 'show-dropzone': idx == dragOverIdx && idx != dragFromIdx && idx != dragFromIdx - 1 }"
          @dragover="onDragover($event, idx)"
          @dragend="onDragEnd($event)"
          @drop="onDrop($event)"
        ></div>
      </div>
    </div>

    <!-- selected Workflows expand -->
    <v-expand-transition>
      <div v-if="selectedWorkflowsIds.length > 0" class="remove-div">
        <div class="fullscreen-center">
          <p class="remove-text">
            <span class="dBlue--text">{{ selectedWorkflowsIds.length }}</span>
            {{ $tc("skills.workflowsSelected", selectedWorkflowsIds.length) }}
            <d-button
              outline
              round
              color="#4689f4"
              class="body-2 text-capitalize btn-width ml-4"
              light
              @click="selectAllWorkflows()"
              >{{ $t("skills.selectAll") }}</d-button
            >
            <d-button
              outline
              round
              color="#4689f4"
              class="body-2 text-capitalize btn-width"
              light
              @click="clearAllWorkflows()"
              >{{ $t("skills.clearAll") }}</d-button
            >
            <d-button
              round
              color="#4689f4"
              class="elevation-0 body-2 white--text text-capitalize btn-width"
              light
              @click="removeWorkflows()"
              >{{ $t("skills.remove") }}</d-button
            >
          </p>
        </div>
      </div>
    </v-expand-transition>
  </div>
</template>
<script>
import IconBase from "../IconBase.vue";
import DIconCircleUnchecked from "../icons/DIconCircleUnchecked.vue";
import DIconCircleChecked from "../icons/DIconCircleChecked.vue";
import DIconTrash from "../icons/DIconTrash.vue";
import DIconNoAccess from "../icons/DIconNoAccess.vue";
import DImage from "../ui_components/DImage.vue";
import { getDisplayTitle } from "@/js/video-player/title-display.js";
import { mapActions } from "vuex";

export default {
  name: "DSkillsDrag",
  props: {
    value: Array,
    isPublished: Boolean,
  },
  components: {
    IconBase,
    DIconCircleUnchecked,
    DIconCircleChecked,
    DIconTrash,
    DIconNoAccess,
    DImage,
    DQuizIndicator: () => import("@/components/DQuizIndicator.vue"),
  },
  data() {
    return {
      selectedWorkflowsIds: [],
      isDragging: false,
      dragOverIdx: -2,
      dragFromIdx: -2,
    };
  },
  created() {},
  methods: {
    ...mapActions("versionHistory", ["openVersionHistory"]),
    getWorkflowDisplayTitle(workflow) {
      const displayLang = this.$i18n.locale;
      return getDisplayTitle(workflow, displayLang);
    },
    //// Drag worklow ///
    onDragStart(event, idx) {
      if (this.isPublished) {
        this.$emit("show-unpublish-list-popup");
      } else {
        this.isDragging = true;
        this.dragFromIdx = idx;
      }
    },
    onDragEnd(event) {
      this.isDragging = false;
      this.dragOverIdx = -2;
      this.dragFromIdx = -2;
    },
    onDragover(event, idx) {
      event.preventDefault();
      if (this.isDragging && this.dragOverIdx != idx) {
        this.dragOverIdx = idx;
      }
    },
    onDrop(event) {
      event.preventDefault();
      if (this.isDragging) {
        //drag to down
        if (this.dragFromIdx < this.dragOverIdx) {
          const newSteps = this.moveItem(this.value, this.dragFromIdx, this.dragOverIdx);
          this.$emit("input", newSteps);
          this.$emit("made-changes");
        } else if (
          //drag to up
          this.dragFromIdx >
          this.dragOverIdx + 1
        ) {
          this.dragOverIdx = this.dragOverIdx + 1;
          const newSteps = this.moveItem(this.value, this.dragFromIdx, this.dragOverIdx);
          this.$emit("input", newSteps);
          this.$emit("made-changes");
        }
      }
    },
    moveItem(arr, fromIndex, toIndex) {
      let newArr = arr;
      for (let k in newArr) {
        const item = newArr[k];
        if (k == fromIndex) {
          newArr.splice(k, 1);
          newArr.splice(toIndex, 0, item);
        }
      }
      return newArr;
    },
    //// UI display ////
    videoDuration(duration) {
      let sec = duration;
      if (!sec || sec === Infinity) return "0:00";
      let minutes = Math.floor(sec / 60);
      let seconds = sec - minutes * 60;
      let padding = seconds < 10 ? "0" : "";
      return minutes + ":" + padding + Math.floor(seconds);
    },
    getStepNum(index) {
      index++;
      if (index < 10) {
        return "0" + index.toString();
      } else {
        return index.toString();
      }
    },
    //// select and remove ////
    addOrRemoveSelectedWorkflows(id) {
      if (this.isPublished) {
        this.$emit("show-unpublish-list-popup");
      } else {
        const inArrayIndex = this.selectedWorkflowsIds.indexOf(id);
        if (inArrayIndex === -1) {
          this.selectedWorkflowsIds.push(id);
        } else {
          this.selectedWorkflowsIds.splice(inArrayIndex, 1);
        }
      }
    },
    selectAllWorkflows() {
      this.selectedWorkflowsIds = [];
      this.value.forEach((workflow) => {
        this.selectedWorkflowsIds.push(workflow.id);
      });
    },
    clearAllWorkflows() {
      this.selectedWorkflowsIds = [];
    },
    removeWorkflows() {
      let newList = [];
      this.value.forEach((workflow) => {
        const inArrayIndex = this.selectedWorkflowsIds.indexOf(workflow.id);
        if (inArrayIndex === -1) {
          newList.push(workflow);
        }
      });
      this.$emit("input", newList);
      this.$emit("made-changes");
      this.selectedWorkflowsIds = [];
    },
    deletedSingleWorkflow(deletedId) {
      if (this.isPublished) {
        this.$emit("show-unpublish-list-popup");
        return;
      }
      if (!this.isDragging) {
        let newList = [];
        this.value.forEach((workflow) => {
          if (workflow.id != deletedId) {
            newList.push(workflow);
          }
        });
        this.$emit("input", newList);
        this.$emit("made-changes");
      }
    },
  },
};
</script>

<style scoped>
.private-label {
  text-transform: capitalize;
  display: inline-block;
  margin-left: 4px;
}
.d-playlist-drag {
  position: relative;
  height: 100%;
  width: 100%;
  padding-bottom: 96px;
  padding-right: 24px;
}

.remove-div {
  position: fixed;
  height: 70px;
  width: 100%;
  background-color: #323338;
  bottom: 0;
  left: 0;
}
.btn-width {
  width: 120px;
  text-transform: none !important;
}
/* workflow-card */
.workflow-item {
  position: relative;
  width: 100%;
}
.workflow-card {
  position: relative;
  height: 108px;
  width: 100%;
}
.checked-circle-div {
  position: absolute;
  height: 100%;
  width: 104px;
  top: 0;
  left: 0;
}
.checked-circle-btn {
  height: 20px;
  width: 20px;
}
.unchecked-circle {
  box-sizing: border-box;
  height: 20px;
  width: 20px;
  border: 1px solid #8d909f;
  border-radius: 10px;
  opacity: 0;
}
.show-unchecked-circle {
  opacity: 1;
  border: 1px solid #8d909f !important;
}
.unchecked-circle:hover {
  opacity: 1;
}
.workflow-card:hover .unchecked-circle {
  border: 1px solid #8d909f;
  opacity: 1;
}
.workflow-index {
  width: 36px;
  color: #52545d;
  font-family: Roboto;
  font-size: 32px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 32px;
  margin-left: 24px;
  padding-top: 16px;
}
.workflow-div {
  position: absolute;
  height: 110px;
  width: calc(100% - 104px);
  top: 0;
  right: 0;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #e7e7e7;
  box-shadow: 0 0 4px -2px rgba(0, 0, 0, 0.15);
}
.workflow-div-darken {
  opacity: 0.4;
}
.workflow-div-darken:hover {
  opacity: 0.7;
}
.workflow-poster {
  position: absolute;
  top: 0;
  left: 0;
  height: 108px;
  width: 192px;
  overflow: hidden;
  background-color: #55565a;
}
.poster-darken {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffff65;
  z-index: 2;
  color: #000000;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
}
.poster-delete {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #52545d;
  z-index: 2;
}
.delete-step-div {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 64px;
  z-index: 4;
}
.delete-video-btn {
  position: absolute;
  right: 24px;
  cursor: pointer;
  opacity: 0;
  border: 1px solid #c7c7c7;
  box-sizing: border-box;
  height: 32px;
  width: 32px;
  border-radius: 20px;
  padding-top: 5px;
}

.delete-video-btn:hover {
  opacity: 1 !important;
}
.workflow-div:hover .delete-video-btn {
  opacity: 0.5;
}

.title-div {
  position: absolute;
  top: 0;
  left: 192px;
  height: 100%;
  width: calc(100% - 256px);
  text-align: left;
}
.center-horizontally {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.title-box {
  position: relative;
  width: 100%;
}

.title-text {
  position: relative;
  margin: 0;
  padding-left: 24px;
  width: 100%;
  color: #4e4e4e;
  font-family: Roboto;
  font-size: 24px;
  letter-spacing: 0.5px;
  line-height: 24px;
}
.time-step-text {
  position: relative;
  margin: 8px 0 0 0;
  height: 16px;
  width: 100%;
  color: #919191;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  padding-left: 24px;
}
.white-dot {
  height: 3px;
  width: 3px;
  background-color: #919191;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 3px;
}
/* drag */
.dropzone-div {
  padding: 6px 0;
}
.dropzone-div-inner {
  position: relative;
  left: 104px;
  right: 0;
  width: calc(100% - 104px);
  border: 1px dashed #8d909f00;
  height: 0px;
  transition: 0.3s;
}
.show-dropzone {
  height: 108px;
  border: 1px dashed #8d909f;
  transition: 0.3s;
}
.active-area {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.active-area-up {
  position: absolute;
  top: 0;
  left: 0;
  height: 50%;
  width: 100%;
}
.active-area-down {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 50%;
  width: 100%;
}
/* remove */
.remove-text {
  color: #ffffff;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
}
.fullscreen-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.quiz-indicator-wrapper {
  padding-left: 24px;
  margin-top: 5px;
}
</style>
