export const namespaced = true;
export const state = {
  isOpenVersionHistory: false,
  workflowId: "",
  currentWorkspace: "",
  currentWorkspaceGroup: "",
  getLatestOnly: false,
};

export const actions = {
  openVersionHistory({ commit }, { workflowId, latest = false }) {
    commit("SET_LASTEST_ONLY", latest);
    commit("SET_WORKFLOW_ID", workflowId);
    commit("SET_IS_OPEN_VERSION_HISTORY", true);
  },
  closeVersionHistory({ commit }) {
    commit("SET_IS_OPEN_VERSION_HISTORY", false);
  },
  setWorkflowId({ commit }, payload) {
    commit("SET_WORKFLOW_ID", payload);
  },
  setCurrentWorkspace({ commit }, payload) {
    commit("SET_CURRENT_WORKSPACE", payload);
  },
  setCurrentWorkspaceGroup({ commit }, payload) {
    commit("SET_CURRENT_WORKSPACE_GROUP", payload);
  },
};

export const mutations = {
  SET_IS_OPEN_VERSION_HISTORY(state, payload) {
    state.isOpenVersionHistory = payload;
  },
  SET_WORKFLOW_ID(state, payload) {
    state.workflowId = payload;
  },
  SET_CURRENT_WORKSPACE(state, payload) {
    state.currentWorkspace = payload;
  },
  SET_CURRENT_WORKSPACE_GROUP(state, payload) {
    state.currentWorkspaceGroup = payload;
  },
  SET_LASTEST_ONLY(state, payload) {
    state.getLatestOnly = payload;
  },
};
