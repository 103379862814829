<template>
  <v-dialog
    content-class="versionHistoryPopup"
    :value="isOpenVersionHistory"
    @input="closeVersionHistory"
    :max-width="maxWidth"
    persistent
  >
    <v-card class="card">
      <v-card-title class="card__title">
        <ul>
          <li>
            <d-icon-version-history-title />
            <p class="ml-2 mr-auto mb-0 d-inline-block">{{ $t("editor.versionHistory") }}</p>
          </li>
        </ul>
        <span class="close-btn" @click="closeVersionHistory"></span>
      </v-card-title>
      <v-card-text class="card__text">
        <loading-view v-if="isOpenLoading" />
        <empty-view v-if="isVersionHistoryDataEmpty" />
        <history-action v-else v-for="history in versionHistoryData" :key="history.id" :history-data="history" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchVersionHistoryByWorkflowId } from "@/server/version-history";
import { loggingError } from "@/server/error-log-server.js";
import { mapActions, mapState } from "vuex";

export default {
  name: "VersionHistoryPopup",
  components: {
    IconBase: () => import("@/components/IconBase.vue"),
    DIconVersionHistoryTitle: () => import("@/components/icons/editor/DIconVersionHistoryTitle.vue"),
    HistoryAction: () => import("@/components/DEditorBuilder/VersionHistory/Fragments/HistoryAction.vue"),
    LoadingView: () => import("@/components/DEditorBuilder/VersionHistory/LoadingView.vue"),
    EmptyView: () => import("@/components/DEditorBuilder/VersionHistory/EmptyView.vue"),
  },
  data() {
    return {
      maxWidth: 850,
      versionHistoryData: [],
      isOpenLoading: false,
    };
  },
  computed: {
    ...mapState("versionHistory", ["getLatestOnly"]),
    isVersionHistoryDataEmpty() {
      return !this.isOpenLoading && this.versionHistoryData.length === 0;
    },
    isOpenVersionHistory() {
      return this.$store.state.versionHistory.isOpenVersionHistory;
    },
    workflowId() {
      return this.$store.state.versionHistory.workflowId;
    },
  },
  methods: {
    ...mapActions("versionHistory", ["openVersionHistory", "closeVersionHistory"]),
    async refreshVersionHistoryData(workflowId) {
      try {
        this.isOpenLoading = true;
        if (!workflowId) {
          return;
        }
        const { ok, data, errorMessage } = await fetchVersionHistoryByWorkflowId({
          workflowId,
          last: this.getLatestOnly,
        });
        if (!ok) {
          console.error(errorMessage);
          return;
        }
        this.versionHistoryData = data.items;
      } catch (error) {
        console.error(error);
        await loggingError(error);
      } finally {
        this.isOpenLoading = false;
      }
    },
  },
  watch: {
    workflowId: {
      immediate: true,
      handler(workflowId) {
        if (!this.isOpenVersionHistory) {
          return;
        }
        this.refreshVersionHistoryData(workflowId);
      },
    },
    isOpenVersionHistory(isOpenVersionHistory) {
      if (!isOpenVersionHistory || !this.workflowId) {
        this.versionHistoryData = [];
        return;
      }
      this.refreshVersionHistoryData(this.workflowId);
    },
  },
};
</script>

<style scoped lang="scss">
.versionHistoryPopup {
  .card {
    background-color: #28292e;
    color: #fff;

    &__title {
      display: flex;
      justify-content: space-between;
      border-bottom: 2px solid #33353a;
      padding: 32px 32px 22px 32px;
      font-size: 16px;
      font-weight: 700;

      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
          display: flex;
          align-items: center;
          position: relative;

          &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: #4689f3;
            left: 0;
            top: calc(100% + 22px);
          }
        }
      }

      .close-btn {
        --roate-angle: 45deg;
        width: 22px;
        height: 22px;
        position: relative;
        cursor: pointer;
        &:before,
        &:after {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: #9397a6;
          top: 50%;
          left: 50%;
          transform-origin: center;
          transform: translate(-50%, -50%) rotate(var(--roate-angle));
        }

        &:after {
          --roate-angle: -45deg;
        }
      }
    }

    &__text {
      position: relative;
      overflow-y: auto;
      max-height: 700px;
      padding: 32px 32px 16px 32px;

      @media (max-height: 900px) {
        max-height: 500px;
      }

      &::-webkit-scrollbar {
        width: 8px;
        background-color: #1d1e21;
      }
      &:hover::-webkit-scrollbar-thumb {
        background-color: #454545;
      }
    }
  }
}
</style>
