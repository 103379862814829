<template>
  <section
    class="PlayerSearch"
    :class="{
      PlayerSearch__forSmallScreen: !isFullscreen,
      PlayerSearch__show: showPlayerSearch,
    }"
  >
    <v-toolbar dark color="#1D1E21" height="80px" class="elevation-0">
      <button @click="$emit('closePlayerSearch')">
        <icon-base color="#fff" height="24" width="24">
          <d-icon-arrow-back />
        </icon-base>
      </button>
      <v-spacer></v-spacer>
      <div class="PlayerSearch__input">
        <div class="PlayerSearch__input-icon">
          <icon-base color="#FFFFFF" height="16" width="16">
            <d-icon-search />
          </icon-base>
        </div>
        <input ref="inputSearch" v-model="searchInput" class="search-input" @keyup="debounceSearchByText()" />
        <button v-if="searchInput" class="PlayerSearch__input--remove" @click="clearSearch()">
          <icon-base color="white" height="12" width="12">
            <d-icon-close-light />
          </icon-base>
        </button>
      </div>
      <v-spacer></v-spacer>
    </v-toolbar>
    <div v-if="showKeyterms" class="PlayerSearch__portraitTitle mobile-only">
      {{ $t("player.suggestedKeyterms") }}
    </div>
    <div v-if="showKeyterms" class="PlayerSearch__keyterms">
      <div class="PlayerSearch__keyterms--title">
        {{ $t("player.suggestedKeyterms") }}
      </div>
      <button
        v-for="(term, idx) in workflow.terms"
        :key="idx"
        class="PlayerSearch__keyterms--keyterm"
        :class="{
          'PlayerSearch__keyterms--selectedKeyterm': selectedKeyterm === term,
        }"
        @click="onclickKeyterms(term)"
      >
        {{ term }}
      </button>
    </div>

    <div
      class="PlayerSearch__results"
      :class="{
        'PlayerSearch__results--fullScreen': !showKeyterms,
      }"
    >
      <d-enter-search :showEnterSearch="searchInput === ''" />
      <div v-if="searchResults.length > 0 && searchInput !== ''" class="PlayerSearch__results--wrap">
        <h3
          v-if="totalResultsNum <= 1"
          v-html="$t('explorer.resultFor', { num: totalResultsNum, input: this.searchInput })"
        ></h3>
        <h3 v-else v-html="$t('explorer.resultsFor', { num: totalResultsNum, input: this.searchInput })"></h3>
        <div v-for="(result, idx) in searchResults" :key="idx" class="result__step">
          <div>
            <span
              class="result__step--title"
              @click="jumpToSearchResult({ stepNumber: result.stepNumber, startTime: 0 })"
            >
              <span class="text-capitalize">
                {{ $t("all.step") }}
                {{ getStepNum(result.stepNumber) }}.
              </span>
              {{ getStepDisplayTitle(result.step) }}
            </span>
          </div>
          <div class="result__step--row">
            <div v-for="(sentence, idx) in result.sentenceResults" :key="idx + sentence.startTime" class="result__card">
              <div
                class="result__card--img"
                @click="jumpToSearchResult({ stepNumber: result.stepNumber, startTime: sentence.startTime })"
              >
                <div v-if="isSearching" class="result__card--progress">
                  <v-progress-circular :size="48" :width="2" indeterminate color="grey darken-3"></v-progress-circular>
                </div>
                <d-lazy-image v-else :aspect-ratio="16 / 9" :src="sentence.poster" />
              </div>
              <div class="result__card--sentence" v-html="sentence.sentenceHtml"></div>
              <div class="result__card--time">{{ getTime(sentence.startTime) }}</div>
            </div>
          </div>
        </div>
      </div>
      <d-no-result v-else :showNoResult="!isSearching && searchResults.length === 0 && searchInput !== ''" />
    </div>
  </section>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import DIconClose2 from "@/components/icons/DIconClose2.vue";
import IconBase from "@/components/IconBase";
import DIconArrowBack from "@/components/icons/DIconArrowBack.vue";
import DIconSearch from "@/components/icons/DIconSearch.vue";
import DIconCloseLight from "@/components/icons/player/DIconCloseLight.vue";
import DLazyImage from "@/components/ui_components/DLazyImage";
import DNoResult from "@/components/ui_components/DNoResult.vue";
import DEnterSearch from "@/components/ui_components/DEnterSearch.vue";
import { getBatchSignedURLs } from "@/server/sign-server";
import { getDisplayTitle } from "@/js/video-player/title-display.js";
import { debounce, escapeRegExp } from "lodash-es";
import Analytics from "@/js/analytics/analytics";

export default {
  name: "PlayerSearch",
  props: {
    steps: {
      type: Array,
    },
    workflow: {
      type: Object,
    },
    showPlayerSearch: {
      type: Boolean,
    },
    subtitlesChoice: {
      type: String,
    },
    isPublic: {
      type: Boolean,
    },
  },
  components: {
    DIconClose2,
    IconBase,
    DIconArrowBack,
    DIconSearch,
    DIconCloseLight,
    DLazyImage,
    DNoResult,
    DEnterSearch,
  },
  watch: {
    showPlayerSearch: function (showPlayerSearch) {
      if (showPlayerSearch) {
        this.$nextTick(() => this.$refs.inputSearch.focus());
      }
    },
  },
  mounted() {
    this.checkKeyterms();
  },
  updated() {
    if (this.steps.length && !this.transcriptions.length) {
      this.setTranscriptions();
    }
    if (this.steps.length && !this.imageMaps.length) {
      this.setImageMaps();
    }
  },
  data() {
    return {
      searchInput: "",
      showKeyterms: false,
      selectedKeyterm: "",
      searchResults: [],
      transcriptions: [],
      imageMaps: [],
      imageMapsIds: [],
      totalResultsNum: 0,
      isSearching: false,
    };
  },
  computed: {
    ...mapState("workflowPlayer", ["isFullscreen"]),
  },
  methods: {
    checkKeyterms() {
      this.showKeyterms = this.workflow.terms && this.workflow.terms.length > 0;
    },
    onclickKeyterms(term) {
      this.selectedKeyterm = term;
      this.searchInput = term;
      this.searchByText();
    },
    getStepDisplayTitle(step) {
      const displayLang = this.subtitlesChoice || this.$i18n.locale;
      return getDisplayTitle(step, displayLang);
    },
    getStepNum(index) {
      index++;
      if (index < 10) {
        return "0" + index.toString();
      } else {
        return index.toString();
      }
    },
    getTime(time) {
      time = parseFloat(time).toFixed(0);
      const minutes = Math.floor(time / 60);
      const seconds = time - minutes * 60;
      const hours = Math.floor(time / 3600);
      time = time - hours * 3600;
      function str_pad_left(string, pad, length) {
        return (new Array(length + 1).join(pad) + string).slice(-length);
      }
      const finalTime = str_pad_left(minutes, "0", 2) + ":" + str_pad_left(seconds, "0", 2);
      return finalTime;
    },
    clearSearch() {
      this.searchInput = "";
      this.totalResultsNum = 0;
      this.selectedKeyterm = "";
      this.searchResults = [];
    },
    debounceSearchByText: debounce(function () {
      this.selectedKeyterm = "";
      this.searchByText();
    }, 600),
    getSearchResults(input) {
      let results = [];
      const escaped = escapeRegExp(input);
      let total = 0;
      let unsignImageMapList = [];
      for (let i = 0; i < this.transcriptions.length; i++) {
        const stepResult = {
          step: this.steps[i],
          stepNumber: i,
          sentenceResults: [],
        };
        const targetImage = this.imageMaps[i];
        let imageList = [];
        for (let j = 0; j < this.transcriptions[i].length; j++) {
          const sentence = this.transcriptions[i][j].sentence;
          const sentenceObj = this.transcriptions[i][j];
          if (sentence.search(new RegExp(escaped, "i")) >= 0) {
            const imageMapIdx = Math.ceil(sentenceObj.startTime);
            const obj = {
              ...sentenceObj,
              imageMapIndex: imageMapIdx,
              poster: targetImage[imageMapIdx],
              sentenceHtml: this.getHighLightedSentence(sentence),
            };
            stepResult.sentenceResults.push(obj);
            total++;
            imageList.push(imageMapIdx);
          }
        }
        if (stepResult.sentenceResults.length) {
          //only keep those with results
          results.push(stepResult);
          unsignImageMapList.push({
            workflowId: this.workflow.id,
            stepId: this.steps[i].id,
            type: "imageMap",
            imageMap: {
              index: imageList,
            },
          });
        }
      }
      return { results, total, unsignImageMapList };
    },
    async searchByText() {
      const input = this.searchInput;
      if (!input) {
        return;
      }
      this.isSearching = true;
      this.searchResults = [];
      const { results, total, unsignImageMapList } = this.getSearchResults(input);
      this.searchResults = results;
      this.totalResultsNum = total;
      if (this.totalResultsNum > 0) {
        const { ok, data, error } = await getBatchSignedURLs({ data: unsignImageMapList, isPublic: this.isPublic });
        if (ok) {
          const newURLs = data.data.item;
          this.updateSearchResultURLs(newURLs);
        } else {
          console.error("Failed to get signed ImageMap URLs", error);
        }
      }
      this.isSearching = false;
      Analytics.setInputChangeTrack({
        category: "PlayerMain",
        action: "Workflow Content Searching",
        name: "Search in Player",
      });
    },
    updateSearchResultURLs(newURLs) {
      for (const newURL of newURLs) {
        const stepIndex = this.searchResults.findIndex((step) => step.step.id === newURL.stepId);
        if (stepIndex === -1) {
          console.warn("Not found mapping step for sign api response");
          continue;
        }
        const newImageMap = newURL.imageMap;
        let newResult = this.searchResults[stepIndex];
        let madeChanges = false;
        this.searchResults[stepIndex].sentenceResults.forEach((result, index) => {
          const key = result.imageMapIndex;
          const hasSignedUrl = newImageMap && newImageMap[key] && newImageMap[key].message === "OK";
          if (hasSignedUrl) {
            newResult.sentenceResults[index].poster = newImageMap[key].signed;
            madeChanges = true;
          }
        });
        if (madeChanges) {
          Vue.set(this.searchResults, stepIndex, newResult);
        }
      }
    },
    getHighLightedSentence(sentence) {
      // escape selectedText to avoid special characters use in the pattern
      const escaped = escapeRegExp(this.searchInput);
      const check = new RegExp("(" + escaped + ")", "gi");
      let hightedSentence = sentence.replace(check, "<span>$1</span>");
      const keywordIndex = sentence.indexOf(this.searchInput);
      const noSpaceLangs = ["zh", "ja-jp", "th-th"];
      const isNoSpaceLang = noSpaceLangs.indexOf(this.workflow.languageCode) > -1;
      // if the sentence is too long, only show the part of the sentence with highLighted inputWord
      if (isNoSpaceLang && keywordIndex > 20) {
        let copyStart = keywordIndex - 10;
        hightedSentence = "..." + hightedSentence.substr(copyStart, hightedSentence.length - copyStart);
      } else if (keywordIndex > 40) {
        let copyStart = keywordIndex;
        let countSpace = 0;
        for (let i = keywordIndex; i > 0; i--) {
          if (sentence.charAt(copyStart) === " ") {
            countSpace++;
            //sub sentence starts by 4 words(5 space) before the highLighted inputWord
            if (countSpace == 5) {
              break;
            }
          }
          copyStart--;
        }
        hightedSentence = "..." + hightedSentence.substr(copyStart, hightedSentence.length - copyStart);
      }
      return hightedSentence;
    },
    jumpToSearchResult(result) {
      this.$emit("jumpToSearchResult", result);
    },
    setTranscriptions() {
      for (let step of this.steps) {
        this.transcriptions.push(JSON.parse(step.transcriptions));
      }
    },
    setImageMaps() {
      for (let step of this.steps) {
        this.imageMaps.push(step.imageMap);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.PlayerSearch {
  z-index: -2;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #121314;
  transition: 0.3s;
  opacity: 0;
  pointer-events: none;
  @include mobile {
    &__forSmallScreen {
      position: fixed;
      height: calc(100% - 28px);
      top: 28px;
    }
  }

  &__show {
    z-index: 10;
    opacity: 1;
    transition: 0.3s;
    pointer-events: auto;
  }
  &__input {
    position: relative;
    height: 40px;
    width: 360px;
    min-width: 50%;
    background: #121314;
    border: 1px solid #33353a;
    border-radius: 20px;
    &-icon {
      position: absolute;
      top: 0;
      left: 0;
      height: 40px;
      width: 48px;
      padding-top: 11px;
    }
    input {
      position: absolute;
      top: 0;
      left: 48px;
      width: calc(100% - 72px);
      height: 38px;
      padding-top: 2px;
      color: #ffffff;
      font-size: 16px;
      line-height: 20px;
    }
    input:focus {
      outline-width: 0;
      outline: 0;
    }
    input::placeholder {
      color: #888888;
      opacity: 1;
      font-size: 16px;
      font-weight: 300;
    }
    &--remove {
      position: absolute;
      top: 0;
      right: 0;
      height: 40px;
      width: 48px;
    }
  }
  &__portraitTitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #9397a6;
    @media screen and (orientation: portrait) {
      position: absolute;
      left: 24px;
      top: 104px;
    }
    @media screen and (orientation: landscape) {
      display: none;
    }
  }
  &__keyterms {
    position: absolute;
    text-align: left;
    border-right: 1px solid #33353a;

    // desktop
    top: 128px;
    left: 0;
    max-width: 284px;
    width: 30%;
    min-height: calc(100% - 176px);
    max-height: calc(100% - 128px);
    padding: 0 24px 48px 4%;
    overflow-y: auto;
    overflow-x: hidden;
    @media screen and (max-width: 959px) {
      top: 108px;
      min-height: calc(100% - 132px);
      max-height: calc(100% - 108px);
      padding: 0 16px 32px 20px;
    }
    @include mobile {
      @media screen and (orientation: portrait) {
        border: 0;
        left: 24px;
        top: 144px;
        height: 44px;
        width: calc(100% - 24px);
        display: flex;
        overflow-y: hidden;
        overflow-x: auto;
      }
      @media screen and (orientation: landscape) {
        top: 128px;
        left: 0;
        max-width: 284px;
        width: 30%;
        min-height: calc(100% - 176px);
        max-height: calc(100% - 128px);
        padding: 0 24px 48px 4%;
        overflow-y: auto;
        overflow-x: hidden;
        @media screen and (max-width: 959px) {
          top: 108px;
          min-height: calc(100% - 132px);
          max-height: calc(100% - 108px);
          padding: 0 16px 32px 20px;
        }
      }
    }
    &--title {
      z-index: 1;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #9397a6;
      background-color: #121314;

      // desktop
      position: sticky;
      top: 0;
      padding-bottom: 20px;

      @include mobile {
        @media screen and (orientation: portrait) {
          display: none;
        }
        @media screen and (orientation: landscape) {
          position: sticky;
          top: 0;
          padding-bottom: 20px;
        }
      }
    }

    &--keyterm {
      position: relative;
      max-width: 100%;
      height: 36px;
      padding: 6px 24px;
      margin-bottom: 24px;
      border-radius: 18px;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      background: #1d1e21;
      white-space: nowrap;

      // desktop
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      @include mobile {
        @media screen and (orientation: portrait) {
          margin-right: 16px;
        }
        @media screen and (orientation: landscape) {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    &--selectedKeyterm {
      background: #4689f4;
    }
  }
  &__keyterms::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &__keyterms:hover::-webkit-scrollbar-thumb {
    background-color: #2c2d32;
  }
  &__results {
    position: absolute;
    right: 0;
    // desktop
    top: 80px;
    width: 70%;
    min-width: calc(100% - 284px);
    height: calc(100% - 80px);

    @include mobile {
      @media screen and (orientation: portrait) {
        top: 190px;
        width: 100%;
        height: calc(100% - 190px);
      }
      @media screen and (orientation: landscape) {
        top: 80px;
        width: 70%;
        min-width: calc(100% - 284px);
        height: calc(100% - 80px);
      }
    }
    &--fullScreen {
      top: 80px;
      width: 100%;
      height: calc(100% - 80px);
    }
    &--wrap {
      position: absolute;
      left: 48px;
      top: 42px;
      width: calc(100% - 48px);
      height: calc(100% - 42px);
      padding-bottom: 48px;
      text-align: left;
      overflow-y: auto;
      overflow-x: hidden;
      @media screen and (max-width: 959px) {
        top: 24px;
        left: 24px;
        height: calc(100% - 24px);
        width: calc(100% - 24px);
      }
    }
    &--wrap:hover::-webkit-scrollbar-thumb {
      background-color: #2c2d32;
    }
    h3 {
      z-index: 1;
      position: sticky;
      top: 0;
      padding-bottom: 20px;
      margin-bottom: 12px;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      color: #9397a6;
      background-color: #121314;
    }
  }
}
.result {
  &__step {
    position: relative;
    margin-bottom: 36px;
    &--title {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      cursor: pointer;
      span {
        font-weight: 400;
        color: #9397a6;
      }
    }
    &--row {
      height: 200px;
      width: 100%;
      margin-top: 24px;
      padding-right: 48px;
      display: flex;
      overflow-x: scroll;
      overflow-y: hidden;
    }
    &--row::-webkit-scrollbar {
      height: 4px;
    }
    &--row:hover::-webkit-scrollbar-thumb {
      background-color: #2c2d32;
    }
  }
  &__card {
    position: relative;
    height: 186px;
    width: 188px;
    min-width: 188px;
    background-color: #1d1e21;
    margin-right: 24px;
    border-radius: 4px;
    overflow: hidden;
    &--img {
      width: 188px;
      height: 106px;
      background-color: rgba(255, 255, 255, 0.02);
      cursor: pointer;
    }
    &--progress {
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &--sentence {
      height: 42px;
      width: 100%;
      color: #e5e5e5;
      font-size: 12px;
      line-height: 17px;
      text-align: left;
      padding: 8px 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      ::v-deep span {
        background-color: #4689f4;
        color: #ffffff;
        border-radius: 2px;
      }
    }
    &--time {
      position: absolute;
      left: 16px;
      bottom: 16px;
      color: #8d909f;
      font-size: 12px;
      line-height: 14px;
    }
  }
}
</style>
