<template>
  <v-dialog
    max-width="640"
    persistent
    :value="isOpenConfirmedPublishPopup"
    content-class="builderPublishConfirmedPopup"
    @input="handleClose"
  >
    <v-card class="card">
      <div class="close-btn" autotest="popup-close-btn" @click="handleClose"></div>
      <v-card-text class="card__text">
        <h3 class="title">{{ $t("builder.publishWorkflow") }}</h3>
        <div class="tip mb-12" v-html="$t('builder.confirmedPublished')" />
        <div class="subtitle mb-4 uppercase" v-if="showOverView">{{ $t("builder.overview") }}</div>
        <template v-for="s in switchs">
          <div class="switch-wrapper" v-if="s.show()" :key="s.key">
            <div class="text-white">
              <font-awesome-icon class="switch-wrapper__icon" :icon="s.icon" color="#fff" size="1x" />
              <span> {{ s.label }} </span>
            </div>
            <d-activate-switch class="switch" :value="s.value" @click="s.value = !s.value" reverse hiddenBorder />
          </div>
        </template>
        <div class="subtitle mt-12 mb-4 uppercase">{{ $t("builder.categories") }}</div>
        <div
          class="numbers-count"
          v-if="categories.length === 0"
          v-html="$t('builder.catAssigned', [categories.length])"
        ></div>
        <v-tooltip top v-else color="#282828" max-width="520">
          <div class="numbers-count" slot="activator" v-html="$t('builder.catAssigned', [categories.length])"></div>
          <span>{{ categoriesTip }}</span>
        </v-tooltip>
        <div class="subtitle mt-12 mb-4 uppercase">{{ $t("builder.languages") }}</div>
        <div
          class="numbers-count mb-12"
          v-if="languages.length === 0"
          v-html="$t('builder.languageAssigned', [languages.length])"
        ></div>
        <v-tooltip v-else top color="#282828" max-width="520">
          <div
            class="numbers-count mb-12"
            slot="activator"
            v-html="$t('builder.languageAssigned', [languages.length])"
          ></div>
          <span>{{ languagesTip }}</span>
        </v-tooltip>
        <div class="description">
          <div class="description__title">
            <font-awesome-icon
              class="chevron-down"
              :class="{ 'fa-rotate-270': !showVerDesc }"
              :icon="['fas', 'chevron-down']"
              size="xs"
              @click="showVerDesc = !showVerDesc"
            />
            {{ $t("builder.versionDescription", { num: versionNumber }) }}
          </div>
          <textarea
            v-show="showVerDesc"
            class="description__input"
            @input="$emit('update:versionDescription', $event.target.value)"
            :value="versionDescription"
          ></textarea>
          <div class="checkbox">
            <label for="re-training" @click="isReTraining = !isReTraining">
              <d-icon-checked-box v-if="isReTraining" />
              <d-icon-check-box v-else />
              {{ $t("quiz.reTrainWarning") }}
            </label>
          </div>
          <div class="alert-tip" v-show="isReTraining">
            <font-awesome-icon class="mt-1" :icon="['far', 'triangle-exclamation']" color="#fda25d" />

            <span>{{ $t("quiz.reTrainTooltipWarning") }}</span>
          </div>
        </div>
      </v-card-text>

      <v-card-actions class="card__actions">
        <d-button
          analytics="confirm-close-buildermain"
          autotest="popup-cancel-btn"
          outline
          round
          color="#4689f4"
          class="text-capitalize card__actions--btn"
          light
          @click="handleClose"
          >{{ $t("all.cancel") }}
        </d-button>
        <d-button
          analytics="confirm-publish"
          autotest="popup-yes-btn"
          round
          color="#4689f4"
          dark
          class="text-capitalize card__actions--btn"
          @click="
            $emit('confirmPublish', {
              nextVersionHistoryNumber: Number(versionNumber) || 1,
              isReTraining,
              quizActive,
              textSOPActive,
              aiSummaryActive,
            })
          "
          >{{ $t("builder.publish") }}
        </d-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchVersionHistoryByWorkflowId } from "@/server/version-history";
import { isEmpty } from "lodash-es";
import DIconCheckedBox from "@/components/icons/builder/DIconCheckedBox.vue";
import { mapState } from "vuex";
import DTooltip from "@/components/ui_components/DTooltip.vue";
import { HISTORY_ACTION_TYPE } from "@/constants/versionHistory";

const SWITCHKEYS = {
  TEXTSOP: "textSOP",
  QUIZ: "quiz",
  AISUMMARY: "aiSummary",
};

export default {
  name: "DBuilderPublishConfirmedPopup",
  props: {
    isOpenConfirmedPublishPopup: Boolean,
    versionDescription: String,
    workflowId: String,
    categories: Array,
    languages: Array,
  },
  components: {
    DIconPublished: () => import("@/components/icons/builder/DIconPublished.vue"),
    DIconCheckBox: () => import("@/components/icons/builder/DIconCheckBox.vue"),
    DIconCircleQuestion: () => import("@/components/icons/builder/DIconCircleQuestion.vue"),
    DIconCheckedBox,
    DActivateSwitch: () => import("@/components/DEditorBuilder/DActivateSwitch.vue"),
    DTooltip,
  },
  methods: {
    handleClose() {
      this.$emit("update:isOpenConfirmedPublishPopup", false);
      this.$emit("update:versionDescription", "");
    },
    async refreshVersionHistoryData(workflowId) {
      const { ok, data, errorMessage } = await fetchVersionHistoryByWorkflowId({ workflowId });
      if (!ok) {
        console.error(errorMessage);
        return;
      }
      this.versionHistoryData = data.items;
    },
  },
  data() {
    return {
      versionHistoryData: [],
      isReTraining: false,
      switchs: [
        {
          key: "aiSummary",
          label: "AI Summary",
          value: false,
          icon: ["fas", "list-dropdown"],
          show: () => this.showAISummarySwitch,
        },
        {
          key: "quiz",
          label: "Quiz",
          value: false,
          icon: ["far", "ballot-check"],
          show: () => this.questions.length > 0,
        },
        {
          key: "textSOP",
          label: "Text SOP",
          icon: ["far", "arrow-progress"],
          value: false,
          show: () => this.showSopSwitch,
        },
      ],
      showVerDesc: true,
    };
  },
  computed: {
    ...mapState("aiQuizEditor", ["questions", "isQuizPublished"]),
    ...mapState("sopEditor", ["sopId"]),
    ...mapState("aiSummary", ["summaryId"]),
    showAISummarySwitch() {
      return this.summaryId;
    },
    showSopSwitch() {
      return this.sopId;
    },
    categoriesTip() {
      const getCatName = (id) => this.$store.state.category.categories.find((c) => c.id === id).name;
      return this.categories.map((id) => getCatName(id)).join(", ");
    },
    languagesTip() {
      const lans = this.$dictionary;
      const locale = this.$i18n.locale === "en" ? "en-us" : this.$i18n.locale;
      return this.languages.map((l) => lans[locale].languageCode[l[0]]).join(", ");
    },
    showOverView() {
      return this.switchs.some((s) => s.show());
    },
    hasQuiz() {
      return this.questions.length > 0;
    },
    quizActive() {
      return this.switchs.find((s) => s.key === SWITCHKEYS.QUIZ).value;
    },
    textSOPActive() {
      return this.switchs.find((s) => s.key === SWITCHKEYS.TEXTSOP).value;
    },
    aiSummaryActive() {
      return this.switchs.find((s) => s.key === SWITCHKEYS.AISUMMARY).value;
    },
    versionNumber() {
      const latestVersionNumber =
        this.versionHistoryData.find((v) => v.actionType === HISTORY_ACTION_TYPE.PUBLISH) || {};
      if (isEmpty(latestVersionNumber)) {
        return (this.versionHistoryData[this.versionHistoryData.length - 1]?.version || 0) + 1;
      }
      return (Number(latestVersionNumber.version) + 1).toFixed(1);
    },
  },
  watch: {
    isOpenConfirmedPublishPopup: {
      immediate: true,
      handler(isOpenConfirmedPublishPopup) {
        if (!isOpenConfirmedPublishPopup) {
          return;
        }
        this.isReTraining = false;
        this.switchs.find((s) => s.key === SWITCHKEYS.QUIZ).value = this.isQuizPublished;
        this.refreshVersionHistoryData(this.workflowId);
      },
    },
    workflowId: {
      immediate: true,
      handler(workflowId) {
        if (!workflowId) {
          return;
        }
        this.refreshVersionHistoryData(workflowId);
      },
    },
    isReTraining(val) {
      if (val) this.showVerDesc = false;
    },
  },
};
</script>

<style scoped lang="scss">
.builderPublishConfirmedPopup {
  .card {
    position: relative;
    background-color: #1e1f22;

    .close-btn {
      --roate-angle: 45deg;
      width: 16px;
      height: 16px;
      position: absolute;
      cursor: pointer;
      right: 24px;
      top: 24px;
      z-index: 10;
      &:before,
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #9397a6;
        top: 50%;
        left: 50%;
        transform-origin: center;
        transform: translate(-50%, -50%) rotate(var(--roate-angle));
      }

      &:after {
        --roate-angle: -45deg;
      }
    }

    &__text {
      text-align: left;
      padding: 24px 32px 0 32px;
      .icon {
        margin-bottom: 24px;
      }
      .title {
        color: #fff;
        font-size: 20px;
        margin-bottom: 4px;
      }

      .subtitle {
        color: #8d909f;
        font-size: 14px;
        &.uppercase {
          text-transform: uppercase;
        }
      }

      .tip {
        color: #8d909f;
        font-size: 16px;
      }

      .description {
        width: 100%;
        color: #fff;
        &__title {
          text-align: left;
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 32px;
          display: flex;
          align-items: center;
          gap: 8px;
        }

        &__input {
          background-color: #121314;
          width: 100%;
          height: 100px;
          outline: none;
          padding: 0.5rem;
          resize: none;
          border-radius: 4px;
        }
      }
    }

    &__actions {
      padding: 32px;
      display: flex;
      align-items: center;
      gap: 32px;

      &--btn {
        flex: 1 0 auto;
      }
    }
  }
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;

  label {
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 16px;
    user-select: none;
    cursor: pointer;
  }

  & > svg {
    cursor: pointer;

    &:hover + .tooltip {
      z-index: 1;
      opacity: 1;
    }
  }

  .tooltip {
    display: flex;
    position: absolute;
    z-index: -1;
    bottom: 115%;
    left: -8px;
    margin: 0;
    width: 540px;
    border: 1px solid var(--dGrey4-color);
    border-radius: 4px;
    background-color: var(--dGrey1-color);
    padding: 8px 16px;
    box-shadow: 0 2px 8px 0 #00000059;
    transition: opacity 0.3s ease-in;
    opacity: 0;
    gap: 16px;
    svg {
      flex: 0 0 16px;
    }
    p {
      margin: 0;
      text-align: left;
      color: #9397a6;
      font-size: 14px;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-color: transparent;
      left: 49%;
      top: 100%;
      border-style: solid;
      border-width: 4px;
    }

    &:before {
      border-top-color: var(--dGrey1-color);
      z-index: 2;
    }

    &:after {
      transform: translateY(1px);
      border-top-color: var(--dGrey4-color);
    }
  }
}
.mb-12 {
  margin-bottom: 48px;
}
.mb-4 {
  margin-bottom: 16px;
}
.mt-1 {
  margin-top: 4px;
}
.mt-12 {
  margin-top: 48px;
}
.switch-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  &__icon {
    margin-right: 8px;
  }
  + .switch-wrapper {
    margin-top: 32px;
  }
  .switch {
    padding: 0 16px;
    height: auto;
  }
}
.numbers-count {
  display: inline-block;
  padding: 10.5px 16px;
  color: white;
  border-radius: 8px;
  border: 1px solid var(--dGrey3-color);
  background: var(--dGrey2-color);
}
.chevron-down {
  transition: all 0.2s;
  cursor: pointer;
  &.raote {
    transform: rotate(-90deg);
  }
}
.alert-tip {
  margin-top: 16px;
  border: 1px solid #fda25d;
  padding: 16px;
  display: flex;
  gap: 8px;
  align-items: flex-start;
}
</style>
