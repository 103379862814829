export const HISTORY_ACTION_TYPE = {
  INITIALIZE: "initialize",
  PUBLISH: "publish",
  DUPLICATION_TARGET: "duplication-target",
  DUPLICATION_SOURCE: "duplication-source",
  MOVEMENT: "movement",
};
export const HISTORY_ACTION_ICONS = {
  initialize: ["far", "file-circle-plus"],
  publish: ["far", "file-lines"],
  "duplication-target": ["far", "copy"],
  "duplication-source": ["far", "copy"],
  movement: ["far", "arrow-right-arrow-left"],
};
