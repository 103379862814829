// refs: https://docs.fontawesome.com/apis/javascript/import-icons/#icon-names
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

// regular
import {
  faArrowPointer,
  faText,
  faPencil,
  faUpRight,
  faChevronLeft,
  faChevronRight,
  faSearch,
  faRectangleList,
  faXmark,
  faCirclePlay,
  faMessageCheck,
  faShare,
  faCopy,
  faThumbsUp,
  faThumbsDown,
  faTriangleExclamation,
  faIndustryWindows,
  faArrowRight as farArrowRight,
  faLightbulb,
  faCirclePlus,
  faPenToSquare,
  faArrowLeft,
  faTrashCan,
  faArrowProgress,
  faChevronsLeft,
  faChevronsRight,
  faMagnifyingGlass,
  faMagnifyingGlassChart,
  faCircleInfo,
  faBallotCheck,
  faComments,
  faImage,
  faScissors,
  faRotate,
  faCircleQuestion,
  faListTimeline,
  faInfoCircle,
  faFileCirclePlus,
  faFileLines,
  faArrowRightArrowLeft,
} from "@fortawesome/pro-regular-svg-icons";

// solid
import {
  faPaperclip,
  faCheck,
  faListUl,
  faA,
  faHighlighter,
  faPause,
  faBold,
  faItalic,
  faClosedCaptioning,
  faClosedCaptioningSlash,
  faCalendar,
  faStopwatch,
  faChartColumn,
  faCircle,
  faEllipsis,
  faSort,
  faSortUp,
  faSortDown,
  faSpinner,
  faArrowRight as fasArrowRight,
  faIndustryWindows as fasIndustryWindows,
  faChevronDown,
  faChevronUp,
  faScrewdriverWrench,
  faAngleLeft,
  faPlus,
  faKey,
  faCircleCheck,
  faCircleXmark,
  faClipboardListCheck,
  faEye,
  faPlay,
  faBolt,
  faGear,
  faChartSimple,
  faBullseyeArrow,
  faRobot,
  faChartPie,
  faStarOfLife,
  faLockKeyhole,
  faListDropdown,
  faTriangleExclamation as faSolidTriangleExclamation,
  faQuoteLeft,
  faMicrochipAi,
} from "@fortawesome/pro-solid-svg-icons";

// thin
import { faCirclePlus as fatCirclePlus } from "@fortawesome/pro-thin-svg-icons";

import { faPen, faSort as falSort } from "@fortawesome/pro-light-svg-icons";

const FaIcon = {
  install(Vue) {
    // regular
    library.add(
      faArrowPointer,
      faText,
      faPencil,
      faUpRight,
      faArrowProgress,
      faBallotCheck,
      faChevronLeft,
      faChevronRight,
      faSearch,
      faRectangleList,
      faXmark,
      faCirclePlay,
      faMessageCheck,
      faShare,
      faCopy,
      faThumbsUp,
      faThumbsDown,
      faTrashCan,
      faTriangleExclamation,
      faIndustryWindows,
      farArrowRight,
      faLightbulb,
      faCirclePlus,
      faPenToSquare,
      faArrowLeft,
      faChevronsLeft,
      faChevronsRight,
      faMagnifyingGlass,
      faMagnifyingGlassChart,
      faCircleInfo,
      faPlus,
      faComments,
      faImage,
      faScissors,
      faRotate,
      faCircleQuestion,
      faListTimeline,
      faInfoCircle,
      faFileCirclePlus,
      faFileLines,
      faArrowRightArrowLeft
    );

    // solid
    library.add(
      faPaperclip,
      faCheck,
      faListUl,
      faA,
      faHighlighter,
      faPause,
      faBold,
      faItalic,
      faClosedCaptioning,
      faClosedCaptioningSlash,
      faCalendar,
      faStopwatch,
      faChartColumn,
      faCircle,
      faEllipsis,
      faSort,
      faSortUp,
      faSortDown,
      faSpinner,
      fasArrowRight,
      fasIndustryWindows,
      faChevronDown,
      faChevronUp,
      faScrewdriverWrench,
      faAngleLeft,
      faKey,
      faCircleCheck,
      faCircleXmark,
      faClipboardListCheck,
      faEye,
      faPlay,
      faBolt,
      faGear,
      faChartSimple,
      faBullseyeArrow,
      faRobot,
      faChartPie,
      faStarOfLife,
      faLockKeyhole,
      faListDropdown,
      faSolidTriangleExclamation,
      faQuoteLeft,
      faMicrochipAi
    );
    // thin
    library.add(fatCirclePlus);

    // light
    library.add(faPen, falSort);
    Vue.component("font-awesome-icon", FontAwesomeIcon);
  },
};

export default FaIcon;
